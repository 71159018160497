@import './../../scss/variables.scss';

.avatar__img{
    width: 113px;
    height: 113px;

    img{
        width: 100%;
        height: 100%;
        border: 1px solid $o-primary;
    }
}